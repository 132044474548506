import React, { useEffect } from 'react';
import './Modal.css';

const Modals = ({ show, content, onClose }) => {
  useEffect(() => {
    if (show && content === 'Generated graphs successfully') {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Automatically close after 3 seconds (adjust as needed)

      return () => clearTimeout(timer);
    }
  }, [show, content, onClose]);

  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <div className="modal-content">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Modals;
