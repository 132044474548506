import info from "../assets/info.svg";

const MAN_info = () => {
    return (
        <div className="MAN_alert_div">
            <div className="MAN_info">
                <img src={info} className="alert_icon"/>
                <header className="MAN_info_text">
                    Confidential information. Usage for MAN internal purposes only.
                </header>
            </div>
        </div>
    );
};

export default MAN_info;
