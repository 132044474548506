import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const CustomButton = styled(Button) ({
    height: '40px',
    textTransform: 'none',
    color: 'rgb(0, 0, 0)',
    fontFamily: 'MAN EUROPE_Bold',
    fontSize: '14px',
    color: 'rgb(48, 60, 73)',
});

const Buttons = ({ 
    handleUpdate, 
    handleReset, 
    handleSubmit, 
    selectedOptions,
    disabled
    }) => {

    return (
        <div className="grid_container" >
            <div className="button_container" >
                <CustomButton onClick={handleUpdate} disabled={disabled} sx={{ marginLeft: '9px', marginTop: '0px'}}>
                    Refine
                </CustomButton>
            </div> 

            <div className="button_container" >
                <CustomButton onClick={handleReset} disabled={disabled} style={{ marginBottom: '0px' }}>
                    Clear all filters
                </CustomButton>
            </div> 

            <div className="button_container" >
                <CustomButton onClick={() => handleSubmit({ selectedValues: selectedOptions })} disabled={disabled} sx={{ marginLeft: '0px', marginTop: '0px'}}>
                    Submit
                </CustomButton>
            </div> 
        </div>
    );
};

export default Buttons;
