import logo from '../assets/MAN_simple 2.png';
import React from 'react';
import '../assets/styles.css';

const Header=() => {
    function handleHelpClick() {
        window.location.href = `mailto:1b1f71ec.m365.man@emea.teams.ms?subject=VUCA // [Add subject]&body=Please describe your problem / feedback / question here. Thank you!`;
    }
    
    return (
        <div className='headers'> 
            <div className='logo-container'> 
                <img className="man-logo" src={logo}/>    
                <header>
                    <h1 className='App_title'> VEHICLE USE CASE ANALYZER </h1>
                </header>
            </div>

            <div className='needHelp'>
            <button className='help-button' onClick={handleHelpClick}>
                    Need Help ?
                </button>
            </div>     
        </div>
    )
};

export default Header;

// const Filters = ({
//     subTab,
//     options,
//     setOptions,
//     selectedOptions,
//     setInitialOptions,
//     setSelectedOptions,
//     setCardsData,
//     tableData,
//     setTableData,
//     setInitialTableData,
//     minValue,
//     setMinValue,
//     maxValue,
//     setMaxValue,
//     initialized,
//     setInitialized,
//     searchVins,
//     setSearchVins,
//     uploadedFileName,
//     setUploadedFileName,
//     csvFile,
//     setCsvFile,
//     submitClicked,
//     updateClicked,
//     disabled,
//     handleFiltersModification,
//   }) => {
//     const [selectedFilter, setSelectedFilter] = useState('Year of operation'); // Set default selected filter
//     const [loading, setLoading] = useState(true);
  
//     useEffect(() => {
//       if (!initialized) {
//         setLoading(false);
//         fetchInitialData();
//       } else {
//         setLoading(false);
//       }
//     }, [initialized]);
  
//     const fetchInitialData = () => {
//       setLoading(true); // Set loading to true when fetching data
//       let baseURL;
  
//       axios.get(${baseURL}/api/options, {
//         params: { subTab },
//       })
//         .then(response => {
//           const initialOptionsData = response.data;
//           const initialSelectedOptions = initialOptionsData.reduce((acc, column) => {
//             acc[column.label] = column.values;
//             return acc;
//           }, {});
  
//           setOptions(initialOptionsData);
//           setInitialOptions(initialOptionsData);
//           setSelectedOptions(initialSelectedOptions);
//           setInitialized(true);
//           setLoading(false);
//         })
//         .catch(error => {
//           console.log('Error fetching options:', error);
//         });
//     };
  
//     const handleFilterSelect = (filter) => {
//       setSelectedFilter(filter);
//     };
  
//     const renderSelectedFilterContent = () => {
//       if (!selectedFilter) return null;
  
//       const filterOptions = options.find(option => option.label === selectedFilter);
  
//       return (
//         <div style={{ marginTop: '20px' }}>
//           <MultiSelectCheckbox
//             label={filterOptions.label}
//             options={filterOptions.values}
//             onChange={(selected) => handleSelectionChange(filterOptions.label, selected)}
//             selected={selectedOptions[filterOptions.label]}
//             disabled={disabled || updateClicked}
//           />
//           {tableData[selectedFilter] && (
//             <div style={{ border: '1px solid #CBD3DC', marginTop: '20px' }}>
//               <Plot
//                 data={tableData[selectedFilter].data}
//                 layout={tableData[selectedFilter].layout}
//                 config={{ responsive: true }}
//                 style={{ width: '100%', height: '100%' }}
//                 useResizeHandler={true}
//                 autosize={true}
//               />
//             </div>
//           )}
//         </div>
//       );
//     };
  
//     return (
//       <div style={{ padding: '15px' }}>
//         <Box>
//           <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
//             {options.map(option => (
//               <div
//                 key={option.label}
//                 onClick={() => handleFilterSelect(option.label)}
//                 style={{
//                   padding: '10px',
//                   cursor: 'pointer',
//                   backgroundColor: selectedFilter === option.label ? '#F0F0F0' : 'white',
//                   border: '1px solid #CBD3DC',
//                   marginBottom: '10px',
//                 }}
//               >
//                 {option.label}
//               </div>
//             ))}
//           </div>
  
//           <div style={{ display: 'flex', flexDirection: 'column' }}>
//             {renderSelectedFilterContent()}
//           </div>
//         </Box>
//       </div>
//     );
//   };
  
//   export default Filters;