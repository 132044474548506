import React from 'react';
import { CustomTabs, CustomTab } from './CustomTabComponents';
import Mileage from './Mileage_content';
import Shift_Mileage from './Shift_content';
import Intraday_Mileage from './Intraday_shift_content';

const TabsComponent = ({ 
    activeTab, 
    handleTabChange,
    submitClicked,
    handleDownload,
    error={error}
    }) => {

        return (
            <div style={{ backgroundColor: 'white', marginBottom: '20px', margin: '30px 60px 60px 60px'}}>
                <div style={{ backgroundColor: 'white', paddingTop: '10px', borderRadius: '4px' }}>
                    <CustomTabs value={activeTab} onChange={handleTabChange}>
                        <CustomTab label='Mileage' />
                        <CustomTab label='Shift between overnight breaks' />
                        <CustomTab label='Intraday shifts' />
                    </CustomTabs>
                </div>
                <div style= {{ marginTop: '20px', backgroundColor:'white' }}>
                    {activeTab === 0 && <Mileage submitClicked={submitClicked} handleDownload={handleDownload} error={error}/>}
                    {activeTab === 1 && <Shift_Mileage submitClicked={submitClicked} handleDownload={handleDownload} error={error}/>}
                    {activeTab === 2 && <Intraday_Mileage submitClicked={submitClicked} handleDownload={handleDownload} error={error}/>}
                </div>
            </div>
        );
    };
    
    export default TabsComponent;





