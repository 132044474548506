import { useGraphContext } from "../GraphContext";
import pako from 'pako';

const useHandleQuery = () => {
  let baseURL;
  if (window.location.hostname === 'vuca-prod.simbaanalytics-dev.rio.cloud') {
    baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
  } else if (window.location.hostname === 'md8gpfz52p.eu-west-1.awsapprunner.com') {
    baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
  } else if (window.location.hostname === 'vuca-dev.simbaanalytics-dev.rio.cloud') {
    baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
  } else if (window.location.hostname === '8jmq9udcvt.eu-west-1.awsapprunner.com') {
    baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
  } else {
    baseURL = 'http://localhost:5000'; 
  }

  const { filters, updateGraphData } = useGraphContext();
  
  const handleQuery = async ({ selectedValues, file}) => {
      // Log the selected values for debugging purposes
      console.log('in handle query', selectedValues);
 
      const formData = new FormData();
      formData.append('data', JSON.stringify(selectedValues)); // Append JSON data
      if (file) {
        formData.append('file', file); // Append CSV file if it exists
      }

      // Make a POST request to the specified endpoint
      const response = await fetch(`${baseURL}/submit`, {
        method: 'POST',
        body: formData,
      });

      // Check if the network response is successful (status code 2xx)
      if (!response.ok) {
        throw new Error(response);
      }

      // Check the Content-Encoding header of the response
      const contentEncoding = response.headers.get('Content-Encoding');
      
      let result;
      if (contentEncoding === 'gzip') {
        // If the response is gzip-compressed, decompress it
        const compressedData = await response.arrayBuffer();
        const decompressedData = pako.ungzip(new Uint8Array(compressedData), { to: 'string' });
        result = JSON.parse(decompressedData);
      } else {
        // If the response isn't gzip-compressed, parse it as JSON directly
        result = await response.json();
      }

      updateGraphData(result);

      console.log('ty3vdev',result)

      // Log the result for debugging purposes
  };

  // console.log('graph_data',graphData);

  return handleQuery;
};

export default useHandleQuery;

