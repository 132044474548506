import { Tabs, Tab, Card, CardHeader, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import PopoverComponent from "./Popover";

// Styled Tabs container
const CustomTabs = styled(Tabs)({
    display: 'flex',
    flex: 1, // Tabs container takes full width
    borderBottom: '1px solid #D3D3D3', // Default color for the border bottom
    borderWidth: '2px',
    color: 'rgb(228, 0, 69)',
    '& .MuiTabs-indicator': {
        display: 'flex',
        backgroundColor: 'white',
        flex: 1, // Tabs container takes full width
        borderBottom: '1px solid #E40045',
        borderWidth: '3px',
    },
}); 

// Styled Tab with conditional styling for Filters tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />) (({
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1, // Tabs container takes full width
    maxWidth: '100%',
    color: 'rgb(48, 60, 73)', 
    border: '1px solid #ffffff',
    fontSize: 20,
    fontFamily: 'MAN EUROPE_Bold',
    backgroundColor: 'white',
    '&:hover': {
        color: 'rgb(48, 60, 73)', 
    },
    '&.Mui-selected': {
        fontSize: 20,
        color: 'rgb(228, 0, 69)', 
        border: '1px solid #ffffff',
        backgroundColor: 'white',
    },
    '&:focus': {
        color: 'rgb(228, 0, 69)', 
        border: '1px solid #ffffff',
        backgroundColor: 'white',
    },
    '&.Mui-selected:focus': {
        color: 'rgb(228, 0, 69)',
    },
})); 

const TabWithIcon = ({ label, icon, selectedIcon, selected, iconStyle = {}, selectedIconStyle = {}, ...props }) => (
    <CustomTab
        {...props}
        label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={selected ? selectedIcon : icon}
                    alt={label}
                    style={{
                        width: '40px',
                        height: '37px',
                        marginRight: '10px',
                        // Apply icon styles and make sure they don't affect tab styles
                        ...iconStyle, 
                        ...(selected ? selectedIconStyle : {}),
                    }}
                />
                <span style={{ color: selected ? 'rgb(228, 0, 69)' : 'rgb(48, 60, 73)' }}>
                    {label}
                </span>
            </div>
        }
    />
);

const CustomCard = styled(Card)({
    border: '1px solid #CBD3DC',
    flex: '1 1 100px',
    // margin: '10px',
    // height:'400px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
});

const CustomCardHeader = styled(CardHeader)({
    color: 'rgb(48, 60, 73)',
    backgroundColor: 'rgb(248, 248, 248)',
    padding:'4px 4px',
    fontSize: 16,
    fontFamily: 'MAN EUROPE_Bold',
    '&.MuiCardHeader-title': {
        color: 'rgb(48, 60, 73)',
        fontSize: 16,
        fontFamily: 'MAN EUROPE_Bold',
    }
})

// Custom styled filter content with scrollable area
const CustomCardContent = styled(CardContent)({
    flex: '1 1 1',
})

const CustomCardHeaderWithPopover = ({ title, id, content }) => (
    <CustomCardHeader
        title={title}
        action={
            <PopoverComponent id={id} content={content} />
        }
    />
);

// Optionally, you can export additional components if needed
export { CustomTabs, CustomTab, TabWithIcon, CustomCard, CustomCardContent, CustomCardHeaderWithPopover };