import React, { useState, useRef } from 'react';
import Mainscreen from './screens/Mainscreen'; // Adjust the path as needed
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';

function App() {
  const { accounts, inProgress } = useMsal();
  const account = accounts[0] || {};
  const isAuthenticated = accounts.length > 0;
 
 
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <div>
        {isAuthenticated ? (
          <Mainscreen />
        ) : (
          <h1>Please sign in</h1>
        )}
      </div>
    </MsalAuthenticationTemplate>
  );
}
  
export default App;
