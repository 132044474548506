import React, { useState, useEffect } from "react";
import { CustomCard, CustomCardContent, CustomCardHeaderWithPopover,Divider } from './CustomComponents';
import Plot from 'react-plotly.js';
import { useGraphContext } from '../GraphContext';
import Spinner from "./Spinner";
import shift from '../assets/Shifts_between_8_hours.png';

const Shift_Mileage = ({ submitClicked, handleDownload, error }) => {
  const [histogramData_mileage, setHistogramData_mileage] = useState(null);
  const [boxPlotData_mileage, setBoxPlotData_mileage] = useState(null);

  const [histogramData_duration, setHistogramData_duration] = useState(null);
  const [boxPlotData_duration, setBoxPlotData_duration] = useState(null);

  const [histogramData_speed, setHistogramData_speed] = useState(null);
  const [boxPlotData_speed, setBoxPlotData_speed] = useState(null);
  
  const [histogramData_count, setHistogramData_count] = useState(null);
  const [boxPlotData_count, setBoxPlotData_count] = useState(null);

  const [histogramData_breaktime, setHistogramData_breaktime] = useState(null);
  const [boxPlotData_breaktime, setBoxPlotData_breaktime] = useState(null);

  const [loading, setLoading] = useState(true); // Loading state

  // Define a style object for bold text
  const boldStyle = {
    fontWeight: 'bold'
  };

  const { filters } = useGraphContext();
  useEffect(() => {
      if (filters) {
        setLoading(true); // Set loading to true when filters change
          try {
              const parsedFilters = JSON.parse(filters.shift_overnight);
              const overnight_shift=JSON.parse(filters.overnight_shift_duration);
              const overnight_shift_count=JSON.parse(filters.overnight_shift_count);
              const overnight_avg_speed_bin=JSON.parse(filters.overnight_avg_speed_bin);
              const overnight_breaktime_before_shift=JSON.parse(filters.overnight_breaktime_before_shift);

              const shift_mileage__km_p90_8h=filters.box.shift_mileage__km_p90_8h;
              const shift_duration__hour_p90_8h=filters.box.shift_duration__hour_p90_8h;
              const cnt_of_shifts_year_8h=filters.box.cnt_of_shifts_year_8h;
              const overnight_avg_speed_bin_box=filters.box.avg_speed_year_8h;
              const breaktime_before_shift__hour_p10_8h=filters.box.breaktime_before_shift__hour_p10_8h;

              setHistogramData_mileage(parsedFilters);
              setHistogramData_duration(overnight_shift);
              setHistogramData_count(overnight_shift_count);
              setHistogramData_speed(overnight_avg_speed_bin);
              setHistogramData_breaktime(overnight_breaktime_before_shift);
              
              setBoxPlotData_mileage(shift_mileage__km_p90_8h);
              setBoxPlotData_duration(shift_duration__hour_p90_8h);
              setBoxPlotData_count(cnt_of_shifts_year_8h);
              setBoxPlotData_speed(overnight_avg_speed_bin_box);
              setBoxPlotData_breaktime(breaktime_before_shift__hour_p10_8h);

          } catch (error) {
              console.error('Error parsing JSON:', error);
          } finally {
            setLoading(false); // Set loading to false after data processing
        }
      }
      else{
        setLoading(false); // Set loading to false after data processing 
    }
  }, [filters]);

  return (
    <div className="KPI_main_div" >

    <div>
        <div className="outer_div_title_onTop_of_KPI" >
            <h6 className="title_onTop_of_KPI" >Definition of "Shift between overnight break"</h6>
        </div>
        <div style={{ display: 'flex', backgroundColor: 'white',alignItems: 'center', padding: '10px'}}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={shift} style={{ width: '90%', height: '250px', margin: 0, padding: 0}}/>
            </div>
            <div style={{ flex: 1, display: 'flex',flexDirection: 'column',textAlign: 'left'}}>
                <div>
                    <ul style={{ fontFamily: 'MAN EUROPE_regular', fontSize: '16px', color: 'rgb(48, 60, 73)'}}>
                        <li>A shift is a vehicle operation between two stops/breaks of a defined period of time.</li>
                        <li>On this page we investigate "shifts between overnight breaks". This means that we investigate shifts <span style={boldStyle}>in between a break time of at least 8 hours.</span></li>
                        <li>Generally speaking this represents a workday (in single driver operation).</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <div style={{ marginTop: '20px'}}>
        <div className="outer_div_title_onTop_of_KPI" >
            <h6 className="title_onTop_of_KPI" >Shift mileage between overnight breaks</h6>
        </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Shift_Mileage_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br /> 
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 50 km</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="shift_mileage_histo"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_mileage ? (
                                    <Plot
                                        data={histogramData_mileage.data}
                                        layout={histogramData_mileage.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Shift_Mileage_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="shift_mileage__km_p90_8h"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_mileage ? (
                                    <Plot
                                        data={boxPlotData_mileage.data}
                                        layout={boxPlotData_mileage.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Duration of shifts between overnight breaks</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Shift_duration_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br />
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 1h</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    }
                    handleDownload={handleDownload}
                    downloadOption="shift_duration_histo_overnight" 
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_duration ? (
                                    <Plot
                                        data={histogramData_duration.data}
                                        layout={histogramData_duration.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Shift_duration_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="shift_duration__hour_p90_8h"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_duration ? (
                                    <Plot
                                        data={boxPlotData_duration.data}
                                        layout={boxPlotData_duration.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Average speed on shifts between overnight breaks</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'overnight_speed_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br /> 
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 10 km/h</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="overnight_avg_speed_bin"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_speed ? (
                                    <Plot
                                        data={histogramData_speed.data}
                                        layout={histogramData_speed.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'overnight_speed_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    }
                    handleDownload={handleDownload}
                    downloadOption="avg_speed_year_8h" 
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_speed ? (
                                    <Plot
                                        data={boxPlotData_speed.data}
                                        layout={boxPlotData_speed.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                        )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Count of shifts between overnight breaks</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Shift_count_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br /> 
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 20</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    }
                    handleDownload={handleDownload}
                    downloadOption="overnight_shift_count" 
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_count ? (
                                    <Plot
                                        data={histogramData_count.data}
                                        layout={histogramData_count.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Shift_count_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="cnt_of_shifts_year_8h"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_count ? (
                                    <Plot
                                        data={boxPlotData_count.data}
                                        layout={boxPlotData_count.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
        <div className="outer_div_title_onTop_of_KPI" >
            <h6 className="title_onTop_of_KPI" >Overnight break time</h6>
        </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'overnight_breaktime_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br /> 
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 1 h</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="overnight_breaktime_before_shift"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_breaktime ? (
                                    <Plot
                                        data={histogramData_breaktime.data}
                                        layout={histogramData_breaktime.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'overnight_breaktime_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    }
                    handleDownload={handleDownload}
                    downloadOption="breaktime_before_shift__hour_p10_8h" 
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_breaktime ? (
                                    <Plot
                                        data={boxPlotData_breaktime.data}
                                        layout={boxPlotData_breaktime.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Shift_Mileage;
