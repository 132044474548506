import React from 'react';
import Buttons from './Buttons';
import Filters from './Filters';
import Modals from './Modal';
import { Tab,Divider, Alert, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomTabs, CustomCard, CustomCardContent, CustomCardHeaderWithPopover } from './CustomTabComponents';

// Styled Tab with conditional styling for Filters tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />) (({
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1, // Tabs container takes full width
    // maxWidth: '10%',
    color: 'rgb(48, 60, 73)', 
    border: '1px solid #ffffff',
    fontSize: 20,
    fontFamily: 'MAN EUROPE_Bold',
    backgroundColor: 'white',
    '&:hover': {
        color: 'rgb(48, 60, 73)', 
    },
    '&.Mui-selected': {
        fontSize: 20,
        color: 'rgb(228, 0, 69)', 
        border: '1px solid #ffffff',
        backgroundColor: 'white',
    },
    '&:focus': {
        color: 'rgb(228, 0, 69)', 
        border: '1px solid #ffffff',
        backgroundColor: 'white',
    },
    '&.Mui-selected:focus': {
        color: 'rgb(228, 0, 69)',
    },
})); 

const FiltersModalContent = ({
    subTab,
    options,
    setOptions,
    selectedOptions,
    setInitialOptions,
    setSelectedOptions,
    setCardsData,
    tableData,
    setTableData,
    setInitialTableData,
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    initialized,
    setInitialized,
    searchVins,
    setSearchVins,
    uploadedFileName, 
    setUploadedFileName,
    activeFilter,
    setActiveFilter,
    csvFile,
    setCsvFile,
    submitClicked,
    setSubmitClicked,
    updateClicked,
    checkedfilters,
    setcheckedfilters,
    appliedfilters,
    setappliedfilters,
    updatedselect,
    setupdatedselect,
    mainfilters,
    setmainfilters,
    filtersModified,
    setFiltersModified,
    disabled,
    handleUpdate,
    handleReset,
    handleSubmit,
    handleSubTabChange,
    handleFiltersModification,
    showFilterModal,
    setShowFilterModal,
    modalFilterContent,
    headerNames
}) => {
    return (
        <div>
            <div style={{ backgroundColor: 'white', borderRadius: '4px', padding: '20px', display: 'flex' }}> 

                <div style={{justifyContent: 'left', width:'12vw'}}>
                <CustomTabs value={subTab} onChange={handleSubTabChange}>
                    <CustomTab label='Truck' />
                    <CustomTab label='Bus' />
                </CustomTabs>
                </div>

                <div style={{ position: 'absolute', marginLeft: '10px' }}>
                    <Snackbar
                        open={filtersModified}
                        autoHideDuration={null}
                        onClose={() => setFiltersModified(false)}
                        style={{position: 'relative', marginLeft: '40vw', marginTop: '20px', paddingLeft: '20px', paddingRight: '50px'}}
                        // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={() => setFiltersModified(false)} severity="warning" sx={{ fontFamily: 'MAN EUROPE_regular', fontSize: '16px', justifyContent:'center'}}>
                            Filters have been modified. Submit the changes to apply.
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            
            <div style={{ backgroundColor: 'white', borderRadius: '4px', marginTop: '20px' }}> {/* Space before the content */}
                <Filters
                    subTab={subTab}
                    options={options}
                    setOptions={setOptions}
                    selectedOptions={selectedOptions}
                    setInitialOptions={setInitialOptions}
                    setSelectedOptions={setSelectedOptions}
                    setCardsData={setCardsData}
                    tableData={tableData}
                    setTableData={setTableData}
                    setInitialTableData={setInitialTableData}
                    minValue={minValue}
                    setMinValue={setMinValue}
                    maxValue={maxValue}
                    setMaxValue={setMaxValue}
                    initialized={initialized}
                    setInitialized={setInitialized}
                    searchVins={searchVins}
                    setSearchVins={setSearchVins}
                    uploadedFileName={uploadedFileName}
                    setUploadedFileName={setUploadedFileName}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    csvFile={csvFile}
                    setCsvFile={setCsvFile}
                    filtersModified={filtersModified}
                    submitClicked={submitClicked}
                    setSubmitClicked={setSubmitClicked}
                    updateClicked={updateClicked}
                    checkedfilters={checkedfilters} 
                    setcheckedfilters={setcheckedfilters}
                    appliedfilters={appliedfilters} 
                    setappliedfilters={setappliedfilters}
                    updatedselect={updatedselect} 
                    setupdatedselect={setupdatedselect}
                    mainfilters={mainfilters} 
                    setmainfilters={setmainfilters}
                    setFiltersModified={setFiltersModified}
                    disabled={disabled}
                    handleFiltersModification={handleFiltersModification}
                    headerNames={headerNames}
                    handleUpdate={handleUpdate} 
                    handleReset={handleReset} 
                    handleSubmit={handleSubmit} 
                />
            </div>
        <Modals show={showFilterModal} content={modalFilterContent} onClose={() => setShowFilterModal(false)} />
    </div>
    );
};

export default FiltersModalContent;
