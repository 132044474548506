import React, { useState, useRef } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import info_icon from '../assets/info_icon.png';

const PopoverComponent = ({ id, content }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [timer, setTimer] = useState(null);
  const popoverRef = useRef(null);

  const handleMouseEnter = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    const newTimer = setTimeout(() => {
      setShowPopover(false);
    }, 1000); // 60 seconds delay
    setTimer(newTimer);
  };

  const popover = (
    <Popover id={id} style={{ minWidth: '350px', }}>
      <Popover.Body>
        <div className="arrow"></div>
        <div
          className="popover-content"
          dangerouslySetInnerHTML={{ __html: content }} // Render HTML content
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={popoverRef}
    >
      <OverlayTrigger
        show={showPopover}
        trigger="manual"
        placement="auto"
        overlay={popover}
      >
        <img className='popover' src={info_icon} alt="info" />
      </OverlayTrigger>
    </div>
  );
};

export default PopoverComponent;



