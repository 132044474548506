import React , { useContext, createContext, useState } from 'react';

const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
    const [filters, setFilters] = useState();

    const updateGraphData = (newGraphData) => {
        setFilters(newGraphData);
      };

    return (
        <GraphContext.Provider value={{ filters, setFilters, updateGraphData }}>
            {children}
        </GraphContext.Provider>
    );
};

export const useGraphContext = () => {
    return useContext(GraphContext);
  };
  