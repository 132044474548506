import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { useGraphContext } from '../GraphContext';
import PopoverComponent from "./Popover";

const CustomCard = styled(Card)({
    border: 'none',
    height: '120px',
    margin: '10px',
    overflowX: 'none',
    flex: 1, // Allow cards to grow equally
});

const CustomCardHeader = styled(CardHeader)({
    color: 'rgb(48, 60, 73)',
    backgroundColor: 'white',
    padding:'4px 4px',
    fontSize: 0,
    height: '50px',
    fontFamily: 'MAN EUROPE_regular',
    '&.MuiCardHeader-title': {
        color: 'rgb(48, 60, 73)',
        fontSize: 12,
        fontFamily: 'MAN EUROPE_regular',
    },
});

const CustomCardContent = styled(CardContent)({
    padding: '4px 4px',
    fontSize: '28px',
    fontFamily: 'MAN EUROPE_Bold',
    fontWeight: 'bold',
    justifyContent: 'center',
    // marginTop: '10px'
});

const CustomCardHeaderWithPopover = ({ title, id, content }) => (
    <CustomCardHeader
        title={title}
        action={
            <PopoverComponent id={id} content={content}  />
        }
    />
);

const CountCards = ({ cardsData, setCardsData }) => {
  const { filters } = useGraphContext(); // Assuming you have a custom hook to get context data

  // useEffect(() => {
  //   if (!filters || !filters.update_cards_data) {
  //     fetchCountData();
  //   } 
  //   else {
  //       setCardsData(filters.update_cards_data);
  //   }
  // }, [filters]);

  useEffect(() => {
    if (filters) {
      setCardsData(filters.update_cards_data);
    } 
  }, [filters]);

  const { active, sold, percent } = cardsData;

  // Function to format number with commas
  const formatNumber = (number) => {
    if (isNaN(number)) return ''; // Return as is if not a number
    return number.toLocaleString('de-DE');      // 'de-DE' for German locale, 'en-IN' for Indian English locale
  };

  const formatPercent = (value) => {
    // Check if value is not empty and is a valid number
    if (!value && value !== 0) {
      return ''; // Return empty string if value is empty or undefined
    }
  
    // Parse the value as a float
    const floatValue = parseFloat(value);
  
    // Check if floatValue is NaN or not a finite number
    if (isNaN(floatValue) || !isFinite(floatValue)) {
      return ''; // Return empty string if floatValue is NaN or not finite
    }
    
    // Round the number to 2 decimal places
    const roundedValue = floatValue.toFixed(2);
  
    // Replace the decimal point with a comma for German formatting
    const formattedNumber = roundedValue.replace('.', ',');
  
    // Append '%' to the formatted number
    const formattedPercent = `${formattedNumber}%`;
  
    return formattedPercent;
  };
  
  return (
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <div style={{ flex: 1 }}>
        <CustomCard>
          <CustomCardHeaderWithPopover title='Activated vehicles' id= 'activated'
            content= {
            `<ul>
                <li>Number of vehicles visualized in statistics - based on filter selection</li>
            </ul>`
            } 
          />
              <CustomCardContent>
              {formatNumber(active)}
              </CustomCardContent>
        </CustomCard>
      </div>

      <div style={{ flex: 1 }}>
        <CustomCard>
          <CustomCardHeaderWithPopover title='Sold vehicles' id= 'sold'
            content= {
            `<ul>
                <li>Number of vehicles sold by MAN - based on filter selection</li>
            </ul>`
            } 
          />
              <CustomCardContent>
              {formatNumber(sold)}
              </CustomCardContent>
        </CustomCard>
      </div>

      <div style={{ flex: 1 }}>
        <CustomCard>
          <CustomCardHeaderWithPopover title='Activated/sold vehicles' id= 'activated/sold'
            content= {
            `<ul>
                <li>Number of vehicles visualized in statistics represent this share of sold vehicles</li>
            </ul>`
            } 
          />
              <CustomCardContent>
                  {formatPercent(percent)}
              </CustomCardContent>
        </CustomCard>
        </div>
    </div>
  );
};

export default CountCards;

