import React , { useContext, createContext, useState } from 'react';

const ResetContext = createContext();

export const ResetProvider = ({ children }) => {
    const [reset, setreset] = useState();

    const updateGraphData = (newGraphData) => {
        setreset(newGraphData);
      };
    
    return (
        <ResetContext.Provider value={{ reset, setreset, updateGraphData}}>
            {children}
        </ResetContext.Provider>
    );
};

export const useResetContext = () => {
    return useContext(ResetContext);
  };
  