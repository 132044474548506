import React, { useState, useEffect } from "react";
import { CustomCard, CustomCardContent, CustomCardHeaderWithPopover,Divider } from './CustomComponents';
import Plot from 'react-plotly.js';
import { useGraphContext } from '../GraphContext';
import Spinner from "./Spinner";
import intraday_shift from '../assets/Shifts_between_30_Minutes.png';

const Intraday_Mileage = ({ submitClicked, handleDownload, error}) => {
  const [histogramData_mileage, setHistogramData_mileage] = useState(null);
  const [boxPlotData_mileage, setBoxPlotData_mileage] = useState(null);

  const [histogramData_duration, setHistogramData_duration] = useState(null);
  const [boxPlotData_duration, setBoxPlotData_duration] = useState(null);

  const [histogramData_speed, setHistogramData_speed] = useState(null);
  const [boxPlotData_speed, setBoxPlotData_speed] = useState(null);
  
  const [histogramData_count, setHistogramData_count] = useState(null);
  const [boxPlotData_count, setBoxPlotData_count] = useState(null);

  const [histogramData_breaktime, setHistogramData_breaktime] = useState(null);
  const [boxPlotData_breaktime, setBoxPlotData_breaktime] = useState(null);

  const [loading, setLoading] = useState(true); // Loading state

  // Define a style object for bold text
  const boldStyle = {
    fontWeight: 'bold'
  };

  const { filters } = useGraphContext();
  useEffect(() => {
      if (filters) {
          try {
              const intraday_milage = JSON.parse(filters.intraday_milage_bin);
              const intraday_duration=JSON.parse(filters.intraday_duration_bin);
              const intraday_count=JSON.parse(filters.intraday_shift_cnt_bin);
              const intraday_avg_speed_bin=JSON.parse(filters.intraday_avg_speed_bin);
              const intraday_breaktime_before_shift=JSON.parse(filters.intraday_breaktime_before_shift);

              const intraday_milage_box = filters.box.shift_mileage__km_p95_30min;              
              const intraday_duration_box=filters.box.shift_duration__hour_p95_30min;              
              const intraday_count_box=filters.box.cnt_of_shifts_year_30min;
              const intraday_avg_speed_bin_box=filters.box.avg_speed_year_30min;
              const breaktime_before_shift__hour_p10_30min=filters.box.breaktime_before_shift__hour_p10_30min;

              setHistogramData_mileage(intraday_milage);
              setHistogramData_duration(intraday_duration);
              setHistogramData_count(intraday_count);
              setHistogramData_speed(intraday_avg_speed_bin);
              setHistogramData_breaktime(intraday_breaktime_before_shift);

              setBoxPlotData_mileage(intraday_milage_box);
              setBoxPlotData_duration(intraday_duration_box);
              setBoxPlotData_speed(intraday_avg_speed_bin_box);
              setBoxPlotData_count(intraday_count_box);
              setBoxPlotData_breaktime(breaktime_before_shift__hour_p10_30min);

          } catch (error) {
              console.error('Error parsing JSON:', error);
          } finally {
            setLoading(false); // Set loading to false after data processing
        }
      }
      else{
        setLoading(false); // Set loading to false after data processing 
    }
  }, [filters]);

  return (
    <div className="KPI_main_div" >

    <div>
        <div className="outer_div_title_onTop_of_KPI" >
            <h6 className="title_onTop_of_KPI" >Definition of "intraday shift"</h6>
        </div>

        <div style={{ display: 'flex', backgroundColor: 'white',alignItems: 'center', padding: '10px'}}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={intraday_shift} style={{ width: '90%', height: '250px', margin: 0, padding: 0}}/>
            </div>

            <div style={{ flex: 1, display: 'flex',flexDirection: 'column',textAlign: 'left'}}>
                <div>
                    <ul style={{ fontFamily: 'MAN EUROPE_regular', fontSize: '16px', color: 'rgb(48, 60, 73)'}}>
                        <li>A shift is a vehicle operation between two stops/breaks of a defined period of time.</li>
                        <li>On this page we investigate "intraday shifts". This means that we investigate shifts <span style={boldStyle}>in between a break time of at least 30 minutes.</span></li>
                        <li>Generally speaking this represents individual single shifts during a workday.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
 
    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Intraday shift mileage</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Intraday_Mileage_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br /> 
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 50 km</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="intraday_milage_bin"
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_mileage ? (
                                    <Plot
                                        data={histogramData_mileage.data}
                                        layout={histogramData_mileage.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Intraday_Mileage_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="shift_mileage__km_p95_30min" 
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_mileage ? (
                                    <Plot
                                        data={boxPlotData_mileage.data}
                                        layout={boxPlotData_mileage.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Duration of intraday shifts</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Intraday_duration_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br />
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 1h</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    }
                    handleDownload={handleDownload}
                    downloadOption="intraday_duration_bin" 
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_duration ? (
                                    <Plot
                                        data={histogramData_duration.data}
                                        layout={histogramData_duration.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Intraday_duration_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="shift_duration__hour_p95_30min" 
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_duration ? (
                                    <Plot
                                        data={boxPlotData_duration.data}
                                        layout={boxPlotData_duration.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Average speed on intraday shifts</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Intraday_speed_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br /> 
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 10 km/h</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="intraday_avg_speed_bin"
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_speed ? (
                                    <Plot
                                        data={histogramData_speed.data}
                                        layout={histogramData_speed.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Intraday_speed_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    }
                    handleDownload={handleDownload}
                    downloadOption="avg_speed_year_30min"  
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_speed ? (
                                    <Plot
                                        data={boxPlotData_speed.data}
                                        layout={boxPlotData_speed.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                        )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Count of intraday shifts</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Intraday_count_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br />
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 50</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="intraday_shift_cnt_bin"
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_count ? (
                                    <Plot
                                        data={histogramData_count.data}
                                        layout={histogramData_count.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Intraday_count_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="cnt_of_shifts_year_30min" 
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_count ? (
                                    <Plot
                                        data={boxPlotData_count.data}
                                        layout={boxPlotData_count.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>

    <div style={{ marginTop: '20px'}}>
      <div className="outer_div_title_onTop_of_KPI" >
        <h6 className="title_onTop_of_KPI" >Intraday break time</h6>
      </div>

        <div className="plot_div" >
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Intraday_breaktime_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br />
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 0,5 h</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="intraday_breaktime_before_shift"
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_breaktime ? (
                                    <Plot
                                        data={histogramData_breaktime.data}
                                        layout={histogramData_breaktime.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Intraday_breaktime_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="breaktime_before_shift__hour_p10_30min" 
                />
                <Divider className="divider" /> 
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData_breaktime ? (
                                    <Plot
                                        data={boxPlotData_breaktime.data}
                                        layout={boxPlotData_breaktime.layout}
                                        config={{ responsive: true }}
                                        style={{ width: '100%', height: '100%' }}
                                        useResizeHandler={true}
                                        autosize={true}
                                        />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Intraday_Mileage;
