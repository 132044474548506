import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { styled } from '@mui/system';

const CustomCheckbox = styled(Checkbox)({
  color: 'grey',
  '&.Mui-checked': {
    color: '#E40045',
  },
});

const MultiSelectCheckbox = ({ options, onChange, selected = [], disabled = false }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  // Set selected options to all available options on component mount or when options change
  useEffect(() => {
    if (options.length > 0 && selected.length === 0) {
      onChange(options); // Select all by default
    }
  }, [options]); // Only run when options change

  useEffect(() => {
    setFilteredOptions(
      options.filter(option => {
        if (typeof option === 'string') {
          return option.toLowerCase().includes(searchTerm.toLowerCase());
        } else if (typeof option === 'number') {
          return option.toString().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );
  }, [searchTerm, options]);

  const handleSelectAll = () => {
    if (selected.length === options.length) {
      onChange([]); // Clear selection in parent
    } else {
      onChange(options); // Select all options in parent
    }
  };

  const handleCheckboxChange = (value) => {
    if (disabled) return; // Prevent changes if disabled

    const newSelectedOptions = selected.includes(value)
      ? selected.filter(option => option !== value)
      : [...selected, value];

    onChange(newSelectedOptions); // Update parent state
  };

  return (
    <div style={{color: 'rgb(48, 60, 73)'}}>
      <TextField
        fullWidth
        variant='outlined'
        size='small'
        placeholder='Search'
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        disabled={disabled}
      />
      <FormGroup sx={{ marginBottom: '3px' }}>
        <FormControlLabel
          control={
            <CustomCheckbox
              checked={selected.length === options.length}
              onChange={handleSelectAll}
              disabled={disabled}
            />
          }
          label='Select All'
        />
        {filteredOptions.map(option => (
          <FormControlLabel
            key={option}
            control={
              <CustomCheckbox
                checked={selected.includes(option)}
                onChange={() => handleCheckboxChange(option)}
                disabled={disabled}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default MultiSelectCheckbox;