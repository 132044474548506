import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/system';
import PopoverComponent from "./Popover";
import downloadIcon from '../assets/download.jpg';

const CustomCard = styled(Card)({
    border: '1px solid #CBD3DC',
    height: '550px',
    margin: '20px',
    overflowX: 'auto',
});

const CustomCardHeader = styled(CardHeader)({
    color: 'rgb(48, 60, 73)',
    backgroundColor: 'rgb(248, 248, 248)',
    padding:'4px 4px',
    fontSize: 16,
    fontFamily: 'MAN EUROPE_Bold',
    '& .MuiCardHeader-title': {
        fontSize: 'inherit',
        fontFamily: 'MAN EUROPE_regular'
    },
});

const CustomCardContent = styled(CardContent)({
    padding: '10px',
});

const CustomCardHeaderWithPopover = ({ title, id, content, handleDownload, downloadOption  }) => (
    <CustomCardHeader
        title={title}
        action={
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <img 
                    src={downloadIcon}  // Ensure you have the download icon imported correctly
                    alt="Download" 
                    style={{ width: '22px', height: '22px', margin: '6px', marginTop: '8px' }} 
                    onClick={() => handleDownload(downloadOption)}  // Call handleDownload on click
                />
            <PopoverComponent id={id} content={content} />
        </div>
        }
    />
); 

// Optionally, you can export additional components if needed
export { CustomCard, CustomCardContent, CustomCardHeaderWithPopover,Divider };